import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import RelatedArticles from "../components/related-articles";

const Blogs = (props) => {
    return <Layout>
        <div className="wrapper">
            <img src={"/images/hero-blog.jpeg"} width={'100%'} alt={"contact us background"} className=""/>
            <div className="mx-auto our-mission md:w-4/5 text-center relative md:bottom-[90px] md:mt-[0] mt-10">
                <h1 className="text-4.5xl font-bold bg-blue-50 text-white pt-[4px] pb-[8px]">Blogs</h1>
                <p className="bg-gray-50 py-[2.25rem] px-8 text-left text-lg">AgeGroup is the premier source of information, activities and products for seniors in an inclusive and non-political environment.
                    <br/>Through our digital universe our partners can access highly targeted communities and seniors based on AgeGroup’s first party data.</p>
            </div>
            <div>
                <div className="flex items-center mb-4">
                    <h1 className="relative text-4.5xl font-bold text-gray-51 news-title shrink-0">
                        Blogs
                    </h1>
                    <span className="head-line"></span>
                </div>
                <RelatedArticles articles={props.data.allDatoCmsArticle.nodes}/>
            </div>
        </div>
    </Layout>;
}

export const query = graphql`
    query {
        allDatoCmsArticle(
            filter: {website: {elemMatch: {name: {eq: "age-group"}}}}
            sort: {order: DESC, fields: publishedAt}
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
    }
`

export default Blogs;
