import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {Navigate} from "@cg-squad/ui-components";

const RelatedArticles = (props) => {
    return <div className={"grid lg:grid-cols-3 gap-8"}>
        {
            props.articles.map((article, index) => {
                return <div className="flex lg:block" key={article.slug}>
                    <GatsbyImage className={index % 3 === 1 ? 'flex-1 lg:h-[56%]' : 'flex-1'}
                                 alt={article.heading}
                                 image={article.thumbnail ? article.thumbnail.gatsbyImageData : article.gatsbyImageData}/>
                    <div className="flex-1 lg:w-11/12 px-2 lg:p-5 mx-auto lg:mb-[15%]">
                        <h1 className="font-semibold text-2xl leading-snug">{article.heading}</h1>
                        <p className="mt-4 text-lg line-clamp l-c-4 leading-snug">{article.excerpt}</p>
                        <div className="line"></div>
                        <div className="text-base lg:text-lg">
                            {/*<time>{formatDate(article.publishedAt, "mmmm dd, yyyy")}</time><span
                                        className="ml-4 text-pink-50">{article.author.name}</span>*/}
                            <Navigate href={`/blogs/${article.slug}`} className={"text-sm flex items-center hover:underline"}>
                                Read More<span className={"ml-2"}><img src={'/images/icons/readmore.png'} alt={"read more"} width={24} height={24}/></span>
                            </Navigate>
                        </div>
                    </div>
                </div>
            })}
    </div>
}

export default RelatedArticles;
